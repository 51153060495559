import api from 'src/services/api'

const ClientRESTService = {
  capturePaypalPayment: (data) => api.post('/v1/client/capture-paypal-payment', data),
  
  sendSMS: (data) => api.post('/v1/client/enviar-sms', data),
  getBookingByTicket: (ticket) => api.get(`/v1/client/booking?ticket=${ticket}`),

}

export default ClientRESTService