import { Card, Row, Col, Input, Button, Checkbox, Select } from 'antd';
import React, { useState } from 'react';
import SubMenu from './SubMenu';

export default function SettingsGeneral({ settingFormInfo }) {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
    { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] },
    { title: 'Equipo', list: ['usuarios', 'roles', 'recursos'] } // Nueva sección "Equipo"
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Datos del formulario:', { nombre, email, mensaje });
    setNombre('');
    setEmail('');
    setMensaje('');
  };

  return (
    <>
      <Row>
        <Col xs={5}>
          <SubMenu settingsList={listMenu} />
        </Col>
        <Col xs={19} className='pl-2'>

          {/* Sección de Configuración General */}
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo || "Configuración General"}</h2>
          </Card>

          {/* Sección del formulario inicial */}
          <Card className='bg-white rounded shadow-none'>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nombre del lugar</label>
                <Input 
                  placeholder="Nombre del lugar" 
                  value={nombre}
                  onChange={(event) => setNombre(event.target.value)}
                />
              </div>

              <div className="form-group mt-4">
                <label>ID y URL de la ubicación</label>
                <Input
                  addonAfter={<Button type="primary">Editar ID de ubicación</Button>}
                  defaultValue="tappit.com/welcome/rufinos"
                />
                <Checkbox className="mt-2">Establecer URL de ubicación como pública</Checkbox>
              </div>

              <div className="form-group mt-4">
                <label>Logo de la compañía</label>
                <div>
                  <img src="ruta-al-logo" alt="Logo de la compañía" style={{ width: '200px' }} />
                </div>
                <Button type="danger" className="mt-2">Eliminar</Button>
              </div>

              <div className="form-group mt-4">
                <label>Icono de ubicación</label>
                <Button type="default">Navegar</Button>
              </div>

              <div className="form-group mt-4">
                <label htmlFor="email">Email:</label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="correo@ejemplo.com"
                />
              </div>

              <div className="form-group mt-4">
                <label htmlFor="mensaje">Mensaje:</label>
                <Input.TextArea
                  id="mensaje"
                  value={mensaje}
                  onChange={(event) => setMensaje(event.target.value)}
                  placeholder="Escribe un mensaje"
                />
              </div>

              <Button type="primary" htmlType="submit" className="mt-4">
                Enviar
              </Button>
            </form>
          </Card>

          {/* Sección de Zona horaria y Horarios de apertura */}
          <Card className='bg-white rounded shadow-none mt-4'>
            <div className="form-group">
              <label>Zona horaria</label>
              <Select placeholder="Selecciona la zona horaria" style={{ width: '100%' }}>
                <Select.Option value="gmt-5">GMT-5</Select.Option>
                <Select.Option value="gmt-6">GMT-6</Select.Option>
                <Select.Option value="gmt-7">GMT-7</Select.Option>
              </Select>
            </div>

            <div className="form-group mt-4">
              <label>Horario de apertura</label>
              <div>
                <ul>
                  <li>Lun 10:00 - 20:00</li>
                  <li>Mar 10:00 - 20:00</li>
                  <li>Mié 10:00 - 20:00</li>
                  <li>Jue 10:00 - 20:00</li>
                  <li>Vie 10:00 - 20:00</li>
                  <li>Sáb Todo el día</li>
                  <li>Dom Todo el día</li>
                </ul>
              </div>
              <Button type="default" className="mt-2">Establecer horario de apertura</Button>
            </div>

            <div className="form-group mt-4">
              <label>Fechas específicas</label>
              <Button type="default" style={{ width: '100%' }}>Establecer horas por fecha</Button>
            </div>
          </Card>

          {/* Sección de Información comercial */}
          <Card className='bg-white rounded shadow-none mt-4'>
            <Row>
              <Col xs={12}>
                <h3 className="font-semibold">Información comercial</h3>
                <p>La información de su empresa se muestra en sus páginas en línea y mensajes a clientes</p>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <p><strong>Nombre del negocio:</strong></p>
                    <p><strong>Tipo de negocio:</strong></p>
                    <p><strong>Teléfono de negocios:</strong></p>
                    <p><strong>Email de negocios:</strong></p>
                    <p><strong>Sitio web de negocios:</strong></p>
                  </Col>
                  <Col xs={12}>
                    <p>Rufinos</p>
                    <p>Bar</p>
                    <p>-</p>
                    <p>-</p>
                    <p>-</p>
                  </Col>
                </Row>
                <Button type="default" className="mt-4">Editar información comercial</Button>
              </Col>
            </Row>
          </Card>

          {/* Sección de Dirección de Negocios */}
          <Card className='bg-white rounded shadow-none mt-4'>
            <Row>
              <Col xs={12}>
                <h3 className="font-semibold">Dirección de Negocios</h3>
                <p>Texto de información de dirección comercial</p>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label>Dirección</label>
                  <Input placeholder="Dirección" />
                  <Input placeholder="Línea de dirección" className="mt-2" />
                </div>

                <div className="form-group mt-4">
                  <label>Ciudad</label>
                  <Input placeholder="Ciudad Juárez" />
                </div>

                <div className="form-group mt-4">
                  <label>Código postal</label>
                  <Input placeholder="Código postal" />
                </div>

                <div className="form-group mt-4">
                  <label>País</label>
                  <Select placeholder="Selecciona el país" style={{ width: '100%' }}>
                    <Select.Option value="mexico">México</Select.Option>
                    <Select.Option value="usa">USA</Select.Option>
                    <Select.Option value="canada">Canadá</Select.Option>
                  </Select>
                </div>

                <div className="form-group mt-4">
                  <label>Provincia del estado</label>
                  <Select placeholder="Selecciona la provincia" style={{ width: '100%' }}>
                    <Select.Option value="chihuahua">Chihuahua</Select.Option>
                    <Select.Option value="nuevo-leon">Nuevo León</Select.Option>
                    <Select.Option value="jalisco">Jalisco</Select.Option>
                  </Select>
                </div>
              </Col>
            </Row>
          </Card>
          
        </Col>
      </Row>
    </>
  )
}
