import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { AnimateLoading } from "../../../_partials/controls";
import { Brand } from "../brand/Brand";
import { QuickSearch } from "../extras/offcanvas/QuickSearch";
import SessionService from "src/services/SessionService";
import { useHistory } from 'react-router-dom';
import BookingService from "src/services/BookingService"; 

export function Header() {
  const uiService = useHtmlClassService();
  const history = useHistory();
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(uiService.config, "header.menu.self.display"),
    };
  }, [uiService]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await SessionService.getSession();
        const userBusinesses = response.data.business;
        setBusinesses(userBusinesses);

        // Recupera el ID del negocio desde localStorage
        const savedBusinessId = localStorage.getItem('provider_tappit_user_business_id');
        setSelectedBusiness(savedBusinessId || userBusinesses[0]?.business_id);
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, []); 

  const handleBusinessChange = async (event) => {
    const newBusinessId = event.target.value;
    setSelectedBusiness(newBusinessId);

   /* const page = 1;
    const count = 10;
    const filterType = 'booking';
    const status = 'pending';
*/
    //const newPath = `/v1/provider/business/${newBusinessId}/booking?page=${page}&count=${count}&filter[type]=${filterType}&status=${status}`;
    const newPath = `/v1/provider/business/${newBusinessId}/booking`;

    console.log(`Cambiado a business ID: ${newBusinessId}`);
    
    try {
      const response = await BookingService.getAll(newBusinessId);
      
      // Actualiza el ID del negocio en localStorage
      localStorage.setItem('provider_tappit_user_business_id', newBusinessId);
      
      history.push(newPath);
    } catch (error) {
      console.error("Error al cargar los datos del negocio:", error);
    }
  };

  return (
    <>
      {/*begin::Header*/}
      <div className={`header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <AnimateLoading />
          <Brand />
          <div className="d-flex align-items-center">
            <select className="form-select me-3" value={selectedBusiness} onChange={handleBusinessChange}>
              {businesses.map((business) => (
                <option key={business.business_id} value={business.business_id}>
                  {business.name}
                </option>
              ))}
            </select>
            <QuickSearch />
          </div>
        </div>
      </div>
      {/*end::Header*/}
    </>
  );
}
