import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Switch,
  Select,
  Tooltip,
  Modal,
  Input,
  Upload,
  message,
  Form,
  Radio,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import React, { useState, useEffect } from 'react'
import SubMenu from './SubMenu'
import BusinessService from 'src/services/BusinessService' // importamos los servicios para la API
import BookingService from 'src/services/BookingService' /// para obtener los recursos

export default function SettingsRecursos({ settingFormInfo }) {
  const [form] = Form.useForm() // Inicializamos el hook Form

  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
    { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] },
    { title: 'Equipo', list: ['usuarios', 'roles', 'recursos'] }, // Sección "Equipo"
  ])

  const [recursos, setRecursos] = useState([
    { key: 1, name: 'VIP 1', type: 'Sillones', status: 'Disponible' },
    { key: 2, name: 'Mesa 1', type: 'Mesas', status: 'Disponible' },
    { key: 3, name: 'Mesa 2', type: 'Mesas', status: 'Disponible' },
    { key: 4, name: 'Mesa 3', type: 'Mesas', status: 'Disponible' },
    { key: 5, name: 'Mesa 4', type: 'Mesas', status: 'Disponible' },
  ])

  const [businessId, setBusinessId] = useState(null) // Estado para almacenar el business_id

  //declaraciones para el modal de edición
  const [isModalVisible, setIsModalVisible] = useState(false) // Para manejar la visibilidad del modal
  const [selectedResource, setSelectedResource] = useState(null) // Para almacenar el recurso seleccionado

  const [isAddModalVisible, setIsAddModalVisible] = useState(false) // Modal de agregar recurso
  const [isEditModalVisible, setIsEditModalVisible] = useState(false) // Modal de editar recurso


  // Recuperamos el business_id de la respuesta a la API al cargar la vista
  useEffect(() => {
    const fetchBusinessId = async () => {
      try {
        const response = await BusinessService.getAll()
        console.log('Respuesta de la API:', response)
        if (response && response.data && response.data.rows && response.data.rows.length > 0) {
          const businessIdFromResponse = response.data.rows[0].id // Accedemos a rows[0]
          setBusinessId(businessIdFromResponse) // Guardamos en el estado
          console.log('business:', businessIdFromResponse)

          // Obtener los recursos del negocio
          const resourcesResponse = await BookingService.getAllResources(businessIdFromResponse)
          console.log('Recursos del negocio:', resourcesResponse)

          // Asigna los recursos obtenidos al estado para renderizarlos en la tabla
          setRecursos(resourcesResponse.data.rows)
        } else {
          console.error('No se encontraron datos de negocios')
        }
      } catch (error) {
        console.error('Error al obtener el business_id:', error)
      }
    }

    fetchBusinessId()
  }, [])

  //const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form
      .validateFields() // Solo validamos los campos que aparecen en el formulario
      .then((values) => {
        const newResourceData = {
          name: values.name,
          capacity: values.capacity || 1,
          vip: values.vip || false,
          zone: values.zone || 'zona',
          type: values.type,
          description: values.description || '',
        }

        // Verifica los datos en la consola antes de hacer la llamada a la API
        console.log('Datos enviados a la API:', newResourceData)

        // Llamada a la API
        BusinessService.createResource(businessId, newResourceData)
          .then((response) => {
            setRecursos([...recursos, { key: response.data.id, ...newResourceData }])
            message.success('Recurso agregado con éxito')
            setIsModalVisible(false)
          })
          .catch((error) => {
            console.error('Error al agregar el recurso:', error)
            message.error('Error al agregar el recurso')
          })
      })
      .catch((info) => {
        console.log('Error en el formulario:', info)
      })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Muestra el modal y carga los datos del recurso seleccionado
  const showEditModal = (record) => {
    setSelectedResource(record) // Guarda el recurso seleccionado
    form.setFieldsValue({
      name: record.name,
      capacity: record.capacity,
      vip: record.vip,
      zone: record.zone,
      type: record.type,
      description: record.description,
    }) // Llena el formulario con los valores actuales del recurso
    setIsModalVisible(true)
  }

  // Maneja el envío de los cambios a la API
  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedResourceData = {
          ...values, // Obtén los nuevos valores del formulario
          is_deleted: false, // Asegúrate de que no se borre el recurso
        }

        // Llama al servicio para actualizar el recurso
        BookingService.updateResource(businessId, selectedResource.id, updatedResourceData)
          .then((response) => {
            message.success('Recurso actualizado con éxito')
            // Aquí actualizas la tabla de recursos con los nuevos datos
            const updatedRecursos = recursos.map((recurso) =>
              recurso.id === selectedResource.id ? { ...recurso, ...updatedResourceData } : recurso,
            )
            setRecursos(updatedRecursos)
            setIsModalVisible(false)
          })
          .catch((error) => {
            message.error('Error al actualizar el recurso')
          })
      })
      .catch((info) => {
        console.log('Error en el formulario:', info)
      })
  }

  // Define las columnas de la tabla, incluyendo el botón de "Editar"
  const columns = [
    {
      title: 'Recurso',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <span
            className={`resource-icon ${record.type === 'Sillones' ? 'yellow-icon' : 'blue-icon'}`}
          >
            {record.name[0]}
          </span>
          <span>{record.name}</span>
        </span>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Select defaultValue={status} style={{ width: 120 }}>
          <Select.Option value="Disponible">Disponible</Select.Option>
          <Select.Option value="No Disponible">No Disponible</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Tooltip title="Editar">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showEditModal(record)} // Llama a showEditModal cuando se haga clic en "Editar"
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} className="ml-2" />
          </Tooltip>
        </span>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col xs={5}>
          <SubMenu settingsList={listMenu} />
        </Col>
        <Col xs={19} className="pl-2">
          {/* Sección de "Recursos" */}
          <Card className="bg-white rounded shadow-none">
            <h2 className="fw-bold">{settingFormInfo || 'Recursos'}</h2>
            <Button type="primary" icon={<PlusOutlined />} className="mt-2" onClick={showModal}>
              Agregar recurso
            </Button>
          </Card>

          {/* Tabla de Recursos */}
          <Card className="bg-white rounded shadow-none mt-4">
            <Select defaultValue="Mostrar todos los recursos" style={{ width: 200 }}>
              <Select.Option value="Todos">Todos</Select.Option>
              <Select.Option value="Sillones">Sillones</Select.Option>
              <Select.Option value="Mesas">Mesas</Select.Option>
            </Select>

            <Table columns={columns} dataSource={recursos} className="mt-4" />
          </Card>

          {/* Configuración de recursos */}
          <Card className="bg-white rounded shadow-none mt-4">
            <h3 className="font-semibold">Configuración</h3>
            <div className="form-group mt-2">
              <Switch defaultChecked /> Los recursos que han iniciado sesión solo pueden ver a los
              invitados a los que pueden atender
            </div>
            <div className="form-group mt-2">
              <Switch /> Asignación automática de recursos en alerta
            </div>
            <div className="form-group mt-2">
              <Switch /> Establezca automáticamente los recursos como Ausentes al final del día
            </div>
            <div className="form-group mt-2">
              <Switch /> Autocompletar clientes para recursos
            </div>
          </Card>

          {/* Visualización de categoría de recursos */}
          <Card className="bg-white rounded shadow-none mt-4">
            <h3 className="font-semibold">Visualización de la categoría de recursos</h3>
            <Select defaultValue="Mostrar todos los recursos en un" style={{ width: '100%' }}>
              <Select.Option value="lista">Lista</Select.Option>
              <Select.Option value="categoría">Categoría</Select.Option>
            </Select>
          </Card>
        </Col>
      </Row>

      {/* Modal para agregar recurso */}
      <Modal
        title="Agregar recurso"
        visible={isModalVisible}
        footer={null} // Esto elimina los botones por defecto
        onCancel={handleCancel} // Mantenemos la posibilidad de cerrar el modal
        width={700}
      >
        <Form layout="vertical" form={form} onFinish={handleOk}>
          {' '}
          {/* Asocia el método handleOk */}
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Nombre del recurso"
                rules={[{ required: true, message: 'Ingrese el nombre del recurso' }]}
              >
                <Input placeholder="Ingrese el nombre del recurso" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="type"
                label="Categoría"
                rules={[{ required: true, message: 'Seleccione una categoría' }]}
              >
                <Select placeholder="Selecciona una categoría">
                  <Select.Option value="mesa">Mesa</Select.Option>
                  <Select.Option value="silla">Silla</Select.Option>
                  {/* Agrega más opciones según sea necesario */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="capacity"
                label="Capacidad"
                rules={[{ required: true, message: 'Ingrese la capacidad' }]}
              >
                <Input placeholder="Capacidad (número de personas)" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="vip" label="VIP">
                <Radio.Group>
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="zone" label="Zona">
                <Input placeholder="Zona (ej: terraza, salón, etc.)" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description" label="Descripción">
                <Input.TextArea placeholder="Descripción del recurso" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {' '}
              {/* Cambia el tipo del botón a submit */}
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal para editar recursos */}
      <Modal
        title="Editar recurso"
        visible={isModalVisible}
        onOk={handleUpdate} // Llama a handleUpdate cuando se guarde el recurso
        onCancel={handleCancel} // Cierra el modal
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="name"
            label="Nombre del recurso"
            rules={[{ required: true, message: 'Ingrese el nombre del recurso' }]}
          >
            <Input placeholder="Ingrese el nombre del recurso" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Categoría"
            rules={[{ required: true, message: 'Seleccione una categoría' }]}
          >
            <Select placeholder="Selecciona una categoría">
              <Select.Option value="mesa">Mesa</Select.Option>
              <Select.Option value="silla">Silla</Select.Option>
              {/*  más opciones si es necesario */}
            </Select>
          </Form.Item>
          <Form.Item
            name="capacity"
            label="Capacidad"
            rules={[{ required: true, message: 'Ingrese la capacidad' }]}
          >
            <Input placeholder="Capacidad (número de personas)" />
          </Form.Item>
          <Form.Item name="vip" label="VIP">
            <Radio.Group>
              <Radio value={true}>Sí</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="zone" label="Zona">
            <Input placeholder="Zona (ej: terraza, salón, etc.)" />
          </Form.Item>
          <Form.Item name="description" label="Descripción">
            <Input.TextArea placeholder="Descripción del recurso" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
